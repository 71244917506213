import React, { FunctionComponent } from "react";
import { Typography, TypographyVariants } from "../components/Typography";
import { Page } from "../components/Page";
/*
import { Link } from "gatsby";

import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <Link to="/page-2/">Go to page 2</Link> <br />
    <Link to="/using-typescript/">Go to "Using TypeScript"</Link>
  </Layout>
);
*/

const IndexPage: FunctionComponent = () => {
  return (
    <Page pageTitle="Home">
      <div className="page-content">
        <div className="main sdv-layout-block">
          <Typography variant={TypographyVariants.H1}>
            Hello! <br />
            I'm Senne <br />
            and I make things...
          </Typography>
        </div>
      </div>
    </Page>
  );
};

export default IndexPage;
